/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Container } from '@mui/material';

import PortableWifiOffIcon from '@mui/icons-material/PortableWifiOff';

import getI18nService from '../i18n/i18n';

import { Context } from '../store/Store';
import GuestButton from './includes/GuestButton';

const i18n = getI18nService();
const env = window['USERSERVICE_ENV'] || 'dev';
const RetryMessage = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useContext(Context);
  const { initialUrl } = state;

  const handleStartOver = () => {
    navigate(initialUrl);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        // minWidth: 400,
        // maxWidth: portalData ? widths[portalData.portalType] : widths['TNC'],
        // maxHeight: 720,
        borderRadius: 4,
        m: 2,
        mt: 10,
        boxShadow: 'rgb(0 0 0 / 25%) 2px 4px 10px 4px',
        border: '1px solid rgb(81, 81, 81)',
      }}
    >
      <Container maxWidth={false}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            color: 'primary.main',
            alignContent: 'center',
            alignItems: 'center',
            m: 2,
          }}
        >
          <Box sx={{ textAlign: 'center', p: 1, fontSize: 'h6.fontSize' }}>{i18n.t('Messages.retryMessage')}</Box>
          <PortableWifiOffIcon sx={{ fontSize: '36px', color: 'primary.main' }} />
        </Box>
        {initialUrl && initialUrl.length > 0 && (
          <Box sx={{ textAlign: 'center', p: 1, mt: 2, color: 'primary.main' }}>
            <GuestButton variant="contained" onClick={handleStartOver}>
              {i18n.t('Vocabulary.startOver')}
            </GuestButton>
          </Box>
        )}
      </Container>
    </Paper>
  );
};

RetryMessage.propTypes = {
  // title: PropTypes.string.isRequired,
};

export default RetryMessage;
