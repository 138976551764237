/* eslint-disable no-console */
import React, { useContext, useEffect, useState, useMemo } from 'react';
// import PropTypes from 'prop-types';

import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import getI18nService from '../../i18n/i18n';
import { Context } from '../../store/Store';
import { getStorageItem } from '../../common/Storage';
import userService from '../../api/UserService';

const i18n = getI18nService();

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PassThroughAccessSuccess = () => {
  const [state, dispatch] = useContext(Context);
  const [tenantName, setTenantName] = useState('-');
  const { api, emailData } = state;
  const portalData = api['get_portal'];
  const query = useQuery();
  const forward = query.get('forward');
  const sessionId = query.get('sessionId');

  useEffect(() => {
    let tName = getStorageItem('tanantName');
    if (portalData) {
      tName = portalData.tenantName;
    }
    let eData = getStorageItem(userService.UserLogin.name);
    if (emailData) {
      eData = emailData;
    }
    setTenantName(tName);
  }, []);

  return (
    <Paper
      elevation={0}
      id="test_guest_passThroughSuccess"
      sx={{
        minWidth: 400,
        borderRadius: 4,
        m: 2,
        mt: 10,
        boxShadow: 'rgb(0 0 0 / 25%) 2px 4px 10px 4px',
        border: '1px solid rgb(81, 81, 81)',
      }}
    >
      <Box
        sx={{
          minWidth: 400,
          mx: 'auto',
          m: 4,
        }}
      >
        <Box sx={{ color: 'primary.main', textAlign: 'center', p: 1, fontSize: 'h4.fontSize' }}>
          {tenantName || '-'}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', color: 'primary.main' }}>
          <Box
            sx={{
              textAlign: 'center',
              fontSize: 'h6.fontSize',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {i18n.t('Messages.tncSuccessMessage', { forward })}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

PassThroughAccessSuccess.propTypes = {};

export default PassThroughAccessSuccess;
