/* eslint-disable no-console */
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DevicesIcon from '@mui/icons-material/Devices';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PersonIcon from '@mui/icons-material/Person';
import { useTheme } from '@mui/material/styles';

import '../themes/quill-nile.css';
import getI18nService from '../i18n/i18n';
import { Context } from '../store/Store';
import { callFetchData } from '../common/Fetch';
import userService from '../api/UserService';
import { setStorageItem } from '../common/Storage';
import Logo from './includes/Logo';
import { createUserSessionSelfRegister } from './selfregister/Utils';
import TermsPassThrough from './passthrough/TermsPassThrough';
import TermsSponsor from './sponsor/TermsSponsor';

const i18n = getI18nService();
const env = window['USERSERVICE_ENV'] || 'dev';
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const modules = {
  toolbar: false,
  clipboard: {
    matchVisual: false,
  },
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SPONSOR_EMAIL = 'SPONSOR_EMAIL';
const TNC = 'TNC';

const widths = {
  [TNC]: '80%',
  [SPONSOR_EMAIL]: 500,
};
const heights = {
  [TNC]: 700,
  [SPONSOR_EMAIL]: 500,
};
const portalTypes = {
  SPONSOR_EMAIL,
  TNC,
};

const success = 'SUCCESS';
const failed = 'FAILED';
const unknown = 'UNKNOWN';

const allowedStatusEnum = { failed, success, unknown };

const forwardTimeout = 1000;
const Terms = () => {
  const theme = useTheme();
  let query = useQuery();
  const navigate = useNavigate();
  const [state, dispatch] = useContext(Context);
  const { ui } = state;
  const { customization } = ui || {};
  const [portalData, setPortalData] = useState(null);
  const { api } = state;
  const userInfo = api[userService.GetUserInfo.name];
  // const [forward, setForward] = useState(false);
  const [selfRegAllowed, setSelfRegAllowed] = useState(false);
  const sessionId = query.get('sessionId');
  const essid = query.get('essid');
  const forward = query.get('forward');
  const mac = query.get('mac');
  const ip = query.get('ip');

  const getTerms = async () => {
    // const authenticated = true;
    const api = Object.assign({}, userService.LookupPortalData, {
      data: { sessionId },
    });
    const result = await callFetchData(api);
    if (result.status === 200) {
      dispatch({
        type: 'api',
        payload: {
          name: api.name,
          response: result.resp,
        },
      });
      const { resp } = result;
      setPortalData(resp);
      const tenantName = resp ? resp.tenantName : '';
      if (resp && resp.allowSSO) {
        setSelfRegAllowed(true);
      }
      setStorageItem('tenantName', tenantName);
    }
  };
  // const selfRegisterAllowed = async () => {
  //   const sessionId = query.get('sessionId');
  //   const api = Object.assign({}, userService.SelfRegisterAllowed, {
  //     data: { sessionId },
  //   });
  //   const result = await callFetchData(api);
  //   if (result.status === 200) {
  //     const status = result.resp ? result.resp.status : '';
  //     setSelfRegAllowed(status === allowedStatusEnum.success);
  //   }
  // };

  const logQuery = () => {
    console.log(
      `${query.get('cmd')} ${query.get('essid')} ${query.get('forward')} ${query.get('ip')} ${query.get(
        'mac'
      )} ${query.get('sessionId')} `
    );
  };

  useEffect(() => {
    getTerms();
    // selfRegisterAllowed();
    logQuery();
  }, []);

  const handleSelfOnboardingForGuest = async () => {
    console.log('Craft SSO url and send the user for login!');
    const sessionId = query.get('sessionId');
    const mac = query.get('mac');
    if (sessionId && mac) {
      const api = Object.assign({}, userService.CreateSsoLoginSessionForGuest, {
        data: { sessionId },
        params: { essid, forward, mac, ip },
      });
      const response = await callFetchData(api);
      let resp = {};
      if (response && response.status === 200) {
        resp = response.resp;
        if (resp && resp.url) {
          window.location.href = resp.url;
        }
      }
    }
  };

  return (
    <>
      {customization && (
        <Paper
          elevation={0}
          sx={{
            // minWidth: 400,
            // maxWidth: portalData ? widths[portalData.portalType] : widths['TNC'],
            // maxHeight: 720,
            borderRadius: 4,
            m: 2,
            mt: 10,
            boxShadow: 'rgb(0 0 0 / 25%) 2px 4px 10px 4px',
            backgroundColor: customization.palettes[0].background.paper || 'primary',
            border: '1px solid rgb(81, 81, 81)',
            opacity: 0.7,
          }}
        >
          {/* {portalData && <Box sx={{ textAlign: 'left', p: 0 }}>{portalData.name}</Box>} */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              mt: 2,
            }}
          >
            <Logo width={50} height={30} data={customization.logo.data} css={customization.logo.css} />
            <Box sx={{ textAlign: 'center', p: 1, fontSize: customization.fonts.headerFontSize || 'h4.fontSize' }}>
              {portalData ? portalData.tenantName : ''}
            </Box>
            {selfRegAllowed && (
              <Tooltip title={i18n.t('Messages.onboardDeviceWithSso')}>
                <IconButton
                  onClick={handleSelfOnboardingForGuest}
                  color="primary"
                  size="large"
                  sx={{ marginLeft: 3 }}
                  aria-label="onboard a device"
                >
                  <PersonIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          {portalData && portalData.portalType === portalTypes[TNC] && (
            <Box sx={{ textAlign: 'center', p: 1, fontWeight: 'medium', color: 'grey.100' }}>
              {i18n.t('Messages.termsOfService')}
            </Box>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{
                maxHeight: 700,
                maxWidth: 1024,
                minWidth: 400,
              }}
            >
              {!portalData && <span>Loading...</span>}
              {portalData && portalData.portalType === portalTypes[TNC] && <TermsPassThrough portalData={portalData} />}
              {portalData && portalData.portalType === portalTypes[SPONSOR_EMAIL] && (
                <TermsSponsor portalData={portalData} />
              )}
            </Box>
          </Box>
        </Paper>
      )}
    </>
  );
};

Terms.propTypes = {
  // title: PropTypes.string.isRequired,
};

export default Terms;
