/* eslint-disable no-console */
import React, { useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import { useLocation, useNavigate } from 'react-router-dom';

import getI18nService from '../../i18n/i18n';
import { Context } from '../../store/Store';
import { getStorageItem } from '../../common/Storage';
import { coaStatusEnum, statusEnum } from '../../common/metadata/DataEnums';
import userService from '../../api/UserService';
import { useInterval } from '../../common/Hooks';
import { callFetchData } from '../../common/Fetch';
// import GuestButton from '../includes/GuestButton';
import HourGlass from '../../components/HourGlass';

const i18n = getI18nService();
// const env = window['USERSERVICE_ENV'] || 'dev';

const endStatuses = [statusEnum.approved, statusEnum.denied, statusEnum.expired, statusEnum.unknown];
const endCoaStatuses = [coaStatusEnum.ack, coaStatusEnum.nAck];

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CheckRequestStatus = () => {
  const [state, dispatch] = useContext(Context);
  const [tenantName, setTenantName] = useState('-');
  const [emailRequest, setEmailRequest] = useState({});
  const { api, emailData } = state;
  const [status, setStatus] = useState(null);
  const [coaStatus, setCoaStatus] = useState(null);
  const portalData = api['get_portal'];
  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    let tName = getStorageItem('tanantName');
    if (portalData) {
      tName = portalData.tenantName;
    }
    let eData = getStorageItem(userService.UserLogin.name);
    if (emailData) {
      eData = emailData;
    }
    setTenantName(tName);
    setEmailRequest(eData);
  }, []);

  const hasSponsorApproved = s => s !== statusEnum.unknown && endStatuses.indexOf(s) > -1;
  const isCoaComplete = coas => endCoaStatuses.indexOf(coas) > -1;
  const isGrantedAccess = (s, coas) => {
    if (!s) {
      s = status;
    }
    if (!coas) {
      coas = coaStatus;
    }
    return hasSponsorApproved(s) && isCoaComplete(coas);
  };

  const handleSponsoredStatusChecks = async () => {
    const sessionId = query.get('sessionId');
    if (status === statusEnum.unknown) {
      return;
    }
    const api = Object.assign({}, userService.FetchSessionStatus, {
      data: { sessionId },
    });
    console.log(`Running status check for ${sessionId}.`);
    const result = await callFetchData(api);
    if (result && result.status <= 204) {
      const { resp } = result;
      setStatus(resp.status);
      setCoaStatus(resp.coaStatus);
      if (isGrantedAccess(resp.status, resp.coaStatus)) {
        window.location.href = '/requestAccessSuccess';
        // navigate({
        //   pathname: '/requestAccessSuccess',
        // });
      }
    }
  };

  useEffect(() => {
    if (status === statusEnum.unknown || coaStatus === coaStatusEnum.nAck) {
      navigate({
        pathname: '/error',
      });
    }
  }, [status, coaStatus]);

  useInterval(() => {
    console.log('Setting up status check');
    handleSponsoredStatusChecks();
  }, 5000);

  return (
    <Paper
      elevation={0}
      sx={{
        minWidth: 400,
        borderRadius: 4,
        m: 2,
        mt: 10,
        boxShadow: 'rgb(0 0 0 / 25%) 2px 4px 10px 4px',
        border: '1px solid rgb(81, 81, 81)',
      }}
    >
      <Box
        sx={{
          minWidth: 400,
          mx: 'auto',
          m: 4,
        }}
      >
        <Box sx={{ color: 'primary.main', textAlign: 'center', p: 1, fontSize: 'h4.fontSize' }}>
          {tenantName || '-'}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', color: 'primary.main' }}>
          <Box
            sx={{
              textAlign: 'center',
              fontSize: 'h6.fontSize',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {status !== statusEnum.unknown && !isGrantedAccess() && (
              <>
                <Box>{i18n.t('Messages.emailRequestSent', { sponsorEmail: emailRequest.sponsorEmail || '-' })}</Box>
                <Box>{i18n.t('Messages.emailRequestAfterApprove')}</Box>
              </>
            )}
            {isGrantedAccess() && <Box>{i18n.t('Messages.sponsoredAccessGranted')}</Box>}
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center', p: 1, mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {status !== statusEnum.unknown && !isGrantedAccess() && (
            <>
              {/* <CircularProgress /> */}
              <Box style={{ width: 50, height: 60 }}>
                <HourGlass />
              </Box>
              <Typography variant="caption" component="div">
                {i18n.t('Messages.waitingForApproval')}
              </Typography>
            </>
          )}
          {/* {false && status !== statusEnum.unknown && (
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <ListItem disablePadding>
                <ListItemIcon>
                  <Checkbox edge="start" checked={hasSponsorApproved()} disableRipple />
                </ListItemIcon>
                <ListItemText primary={i18n.t('Messages.emailRequestApproved')} />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <Checkbox edge="start" checked={isCoaComplete()} disableRipple />
                </ListItemIcon>
                <ListItemText primary={i18n.t('Messages.coaCompleted')} />
              </ListItem>
            </List>
          )} */}

          {/* <GuestButton variant="outlined" onClick={handleSponsoredCoaStatusCheck}>
            {i18n.t('Vocabulary.checkStatus')}
          </GuestButton> */}
        </Box>
        {status === statusEnum.unknown && <Box sx={{ color: 'error.main' }}>{i18n.t('Messages.errorWithAccess')}</Box>}
      </Box>
    </Paper>
  );
};

CheckRequestStatus.propTypes = {
  // portalData: PropTypes.object.isRequired,
  // emailData: PropTypes.object.isRequired,
};

export default CheckRequestStatus;
