import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
// import CssBaseline from '@material-ui/core/CssBaseline';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import GlobalStyles from '@mui/material/GlobalStyles';

import { ErrorBoundary } from 'react-error-boundary';

// import getLogger from './common/Logger';
import { callFetchData } from './common/Fetch';
import darkOverrides from './themes/dark';
import lightOverrides from './themes/light';
import Store from './store/Store';
import Fallback from './components/Fallback';
import HomePageLayout from './layouts/HomePageLayout';
import { getStorageItem } from './common/Storage';
import Footer from './pages/includes/Footer';
import userService from './api/UserService';

const theme = createTheme(darkOverrides);
// const theme = createTheme(lightOverrides);

const inputGlobalStyles = (theme, customization) => (
  <GlobalStyles
    styles={{
      '::-webkit-scrollbar': {
        height: '8px',
        width: '8px',
        borderRadius: '5px',
        background: theme.palette.background.light,
        paddingTop: 1,
        paddingBottom: 1,
      },
      '::-webkit-scrollbar-thumb': {
        background: theme.palette.grey['500'],
        '-webkit-border-radius': '1ex',
        '-webkit-box-shadow': '0px 1px 2px rgba(0, 0, 0, 0.75)',
      },
      '::-webkit-scrollbar-corner': {
        background: theme.palette.background.default,
      },
      // body: {
      //   backgroundImage: `url('${customization ? customization.background.data : 'null'}')`,
      // },
    }}
  />
);

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const UserService = () => {
  // const classes = useStyles();
  let query = useQuery();
  const [customization, setCustomization] = useState(null);
  const [customizedTheme, setCustomizedTheme] = useState(createTheme(darkOverrides));

  const setThemeData = themeData => {
    const palette = themeData.palettes[0];
    darkOverrides.palette.primary.main = palette.primary.main;
    darkOverrides.palette.secondary.main = palette.secondary.main;
    darkOverrides.palette.background.paper = palette.background.paper;
    setCustomization(themeData);
    setCustomizedTheme(createTheme(darkOverrides));
    document.body.style.backgroundImage = `url('${themeData.background.data}')`;
    document.body.style['backgroundSize'] = 'cover';
    document.body.style['backgroundPosition'] = 'center';
  };

  const tryBrandCustomizationV1 = async params => {
    const api = Object.assign({}, userService.GetBrandCustomization, {
      params,
    });
    const result = await callFetchData(api);
    if (result.status === 200) {
      const { resp } = result;
      const { attribute = 'nile' } = resp;
      console.log('V1 branding data', result);
      let prefix = '';
      if (window['USERSERVICE_ENV'] && window['USERSERVICE_ENV'] === 'dev') {
        prefix = '/static';
      }
      if (attribute !== 'default') {
        try {
          console.log(`${prefix}/brand/${attribute}.json`);
          const brandResp = await fetch(`${prefix}/brand/${attribute}.json`);
          const jsonData = await brandResp.json();
          setThemeData(jsonData);
          return true;
        } catch (e) {
          console.error('Invalid branding data');
          return false;
        }
      }
    }
    return false;
  };

  const tryBrandCustomizationV2 = async params => {
    try {
      const api = Object.assign({}, userService.GetBrandCustomizationV2, {
        params,
      });
      const result = await callFetchData(api);
      if (result.status === 200 && result.resp !== null) {
        const { content } = result.resp;
        if (
          !content ||
          !content.values ||
          content.values.length < 2 ||
          !content.values[1].value ||
          content.values[1].value.length === 0
        ) {
          throw new Error('Invalid content received.');
        }

        const themeData = content.values[1].value[0];
        setThemeData(themeData);
        return true;
      } else {
        throw new Error('Invalid response status or response data.');
      }
    } catch (error) {
      console.error('Error in tryBrandCustomizationV2:', error);
      return false;
    }
  };

  const getThemeCustomization = async () => {
    const tenantToken = getStorageItem('tenantToken');
    const sessionId = query.get('sessionId');
    // const sessionId = getStorageItem('tenantToken');
    const params = {};
    if (sessionId) {
      params.sessionId = sessionId;
    } else if (tenantToken) {
      params.tenantToken = tenantToken;
    }

    const brandV2Stat = await tryBrandCustomizationV2(params);
    console.log('brandV2Stat', brandV2Stat);
    if (brandV2Stat) {
      return;
    } else {
      const brandV1Stat = await tryBrandCustomizationV1(params);
      console.log('brandV1Stat', brandV1Stat);
      if (brandV1Stat) {
        return;
      } else {
        // No branding info, use default
        console.log('No branding info, use default');
        const defaultBrand = await fetch(`/brand/newNile.json`);
        const defaultJson = await defaultBrand.json();
        setThemeData(defaultJson);
      }
    }
  };

  useEffect(() => {
    getThemeCustomization();
  }, []);

  return (
    <ThemeProvider theme={customizedTheme}>
      <CssBaseline />
      {inputGlobalStyles(customizedTheme, customization)}
      <Store>
        <Box
          sx={{
            flexGrow: 1,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <ErrorBoundary fallback={<Fallback />}>
            <HomePageLayout customization={customization} />
          </ErrorBoundary>
        </Box>
        <Box
          sx={{
            bottom: 0,
            width: '100%',
            height: 50,
            backgroundColor: 'rgb(250, 251, 252)',
            position: 'fixed',
          }}
        >
          <Footer />
        </Box>
      </Store>
    </ThemeProvider>
  );
};

UserService.propTypes = {
  // title: PropTypes.string.isRequired,
};

export default UserService;
