/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Container } from '@mui/material';

import PortableWifiOffIcon from '@mui/icons-material/PortableWifiOff';

import getI18nService from '../../i18n/i18n';

const i18n = getI18nService();
const PassThroughError = () => {
  return (
    <Paper
      id="test_guest_passThroughError"
      elevation={0}
      sx={{
        borderRadius: 4,
        m: 2,
        mt: 10,
        boxShadow: 'rgb(0 0 0 / 25%) 2px 4px 10px 4px',
        border: '1px solid rgb(81, 81, 81)',
      }}
    >
      <Container maxWidth={false}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            color: 'primary.main',
            alignContent: 'center',
            alignItems: 'center',
            m: 2,
          }}
        >
          <Box sx={{ textAlign: 'center', p: 1, fontSize: 'h4.fontSize' }}>{i18n.t('Messages.retryMessage')}</Box>
          <PortableWifiOffIcon sx={{ fontSize: '72px' }} />
        </Box>
      </Container>
    </Paper>
  );
};

PassThroughError.propTypes = {
  // title: PropTypes.string.isRequired,
};

export default PassThroughError;
