/* eslint-disable no-console */
import React, { useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import AppleIcon from '@mui/icons-material/Apple';
import DeleteIcon from '@mui/icons-material/Delete';
import CableIcon from '@mui/icons-material/Cable';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import WifiIcon from '@mui/icons-material/Wifi';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useLocation } from 'react-router-dom';
import getI18nService from '../../i18n/i18n';
import userService from '../../api/UserService';
import { Context } from '../../store/Store';
import Confirm from '../../components/Confirm';
import GuestButton from '../includes/GuestButton';
import { osToIcon } from '../../components/OsIcons';
import { getStatusCell } from '../../components/Statuses';
import useStyles from './SelfRegister.jss';

import { deleteDevice, getAllDevices, typeWired, typeWireless } from './Utils';
import { callFetchData } from '../../common/Fetch';
import { Typography } from '@mui/material';

const i18n = getI18nService();
// const env = window['USERSERVICE_ENV'] || 'dev';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const deviceTypes = [typeWired, typeWireless];

const UserDevices = props => {
  const {
    deviceList,
    type,
    showHeader,
    userInfo,
    onDeviceDelete,
    keys,
    refreshUserKeys,
    setErrorMessage,
    setShowError,
    withMove,
  } = props;
  const classes = useStyles();
  const [state, dispatch] = useContext(Context);
  const theme = useTheme();
  const [expanded, setExpanded] = useState('panel1');
  const [devices, setDevices] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedMacAddress, setSelectedMacAddress] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedKey, setSelectedKey] = useState('');
  const [selectedDevice, setSelectedDevice] = useState({});
  const [filteredKeys, setFilteredKeys] = useState([]);
  const [moveFailedMessage, setMoveFailedMessage] = useState(null);

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  let query = useQuery();
  const forward = query.get('forward');
  const sessionId = query.get('sessionId');
  const name = 'John';

  const fetchDevices = async () => {
    const result = await getAllDevices(userInfo);
    if (result) {
      const filtered = result.filter(i => type.indexOf(i.device_type) > -1);
      setDevices(filtered);
    }
  };

  useEffect(() => {
    if (!deviceList || deviceList.length === 0) {
      fetchDevices();
    } else {
      const filtered = deviceList.filter(i => type.indexOf(i.device_type) > -1);
      setDevices(filtered);
    }
  }, [deviceList]);

  const handleDeleteDevice = async evt => {
    if (selectedMacAddress) {
      const response = await deleteDevice(userInfo, selectedMacAddress);
      onDeviceDelete(selectedMacAddress);
      setSelectedMacAddress(null);
      return response;
    }
  };

  const handleOnConfirm = confirmed => {
    if (confirmed) {
      console.log(`About to remove ${selectedMacAddress}`);
      handleDeleteDevice();
    } else {
      console.log(`Cancelled remove ${selectedMacAddress}`);
      setSelectedMacAddress(null);
    }
    // console.log(`confirmed: ${confirmed}`);
    setOpenConfirm(false);
  };

  const handleRemoveDevice = evt => {
    const { macAddress } = evt.currentTarget.dataset;
    setSelectedMacAddress(macAddress);
    setOpenConfirm(true);
  };

  const getNoDevicesMkp = () => (
    <TableRow
      sx={{ td: { borderColor: theme.palette.text.secondary }, '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell colSpan={6} align="center">
        No devices registered
      </TableCell>
    </TableRow>
  );

  const getTypeIcon = type => {
    let icon = <WifiIcon />;
    if (type === typeWireless) {
      icon = <WifiIcon />;
    }
    if (type === typeWired) {
      icon = <CableIcon />;
    }
    return icon;
  };

  const getDeviceIcon = os => {
    let icon = <AppleIcon sx={{ color: theme.palette.primary.main, fontSize: 18 }} />;
    // if (type === typeWireless) {
    //   icon = <WifiIcon />;
    // }
    // if (type === typeWired) {
    //   icon = <CableIcon />;
    // }
    return icon;
  };

  const onCloseModal = () => {
    setMoveFailedMessage(null);
    setOpenModal(false);
  };

  const getFilteredKeys = dv => {
    const filteredKeysList = keys.filter(k => k.key_id !== dv.key_id);
    return filteredKeysList;
  };

  const handleMoveDevice = evt => {
    const { macAddress } = evt.currentTarget.dataset;
    if (!macAddress) {
      return;
    }
    setSelectedMacAddress(macAddress);
    const seltdDevice = devices.find(el => el.user_mac === macAddress);
    setSelectedDevice(seltdDevice);

    const filteredKeys = getFilteredKeys(seltdDevice);
    setFilteredKeys(filteredKeys);
    setOpenModal(true);
  };

  const handleKeyChange = evt => {
    setSelectedKey(evt.target.value);
  };
  const onSubmitKey = async () => {
    const resp = await handleDeleteDevice();
    if (!resp) {
      setMoveFailedMessage(i18n.t('Messages.moveFailed'));
      return;
    }
    const api = Object.assign({}, userService.AddUpskDevice, {
      data: {
        sessionId: userInfo.session_id,
      },
      body: {
        key_id: selectedKey.key_id,
        user_mac: selectedDevice.user_mac, //  selectedMacAddress,
        description: selectedDevice.description,
        device_type: typeWireless,
        tenant_id: userInfo.tenant_token,
      },
    });
    const result = await callFetchData(api);
    if (result?.status <= 204) {
      refreshUserKeys();
      setOpenModal(false);
      setErrorMessage(`Moved device to ${selectedKey.name} key`);
      setShowError(true);
    }
  };

  return (
    <Box sx={{ margin: 1 }}>
      <TableContainer sx={{ borderWidth: 1, borderStyle: 'solid', borderColor: theme.palette.grey['800'] }}>
        <Table id="test_self_register_userDevices" sx={{ minWidth: 650 }} aria-label="simple table">
          {showHeader && (
            <TableHead>
              <TableRow sx={{ th: { fontWeight: 600, borderColor: theme.palette.grey['800'] } }}>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Device</TableCell>
                <TableCell>MAC Address</TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="center">Connection</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {devices.length === 0 && getNoDevicesMkp()}
            {devices.map((device, index) => (
              <TableRow
                key={`${device.name}-${device.user_mac}`}
                sx={{
                  backgroundColor: index % 2 === 0 ? theme.palette.background.paper : theme.palette.background.default,
                  td: { border: 0 },
                }}
              >
                <TableCell align="center">{getStatusCell(device.status)}</TableCell>
                <TableCell align="center">{osToIcon(device.device_os)}</TableCell>
                <TableCell>{device.user_mac}</TableCell>
                <TableCell>{device.user_name}</TableCell>
                <TableCell align="center">{getTypeIcon(device.device_type)}</TableCell>
                <TableCell>{device.description}</TableCell>
                <TableCell>
                  <IconButton
                    id={`test_self_register_removeDeviceBtn_${device.user_mac}`}
                    aria-label="delete"
                    data-mac-address={device.user_mac}
                    size="small"
                    title={i18n.t('SelfRegister.removeDevice')}
                    onClick={handleRemoveDevice}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                  {withMove && (
                    <IconButton
                      aria-label="move"
                      id={`test_self_register_moveDeviceBtn_${device.user_mac}`}
                      data-mac-address={device.user_mac}
                      sx={{ marginLeft: 2 }}
                      size="small"
                      title={i18n.t('SelfRegister.moveDevice')}
                      onClick={handleMoveDevice}
                    >
                      <ArrowCircleRightIcon fontSize="small" />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Confirm
        open={openConfirm}
        id="test_self_register_removeDevice_confirm"
        title={i18n.t('SelfRegister.removeDevice')}
        text={i18n.t('SelfRegister.removeDeviceConfirmMessage')}
        onConfirm={handleOnConfirm}
      />

      <Dialog onClose={onCloseModal} id="test_self_register_modal" open={openModal} maxWidth="sm">
        <DialogTitle>Select key</DialogTitle>
        <DialogContent>
          <Box sx={{ width: 300, margin: 4 }}>
            <FormControl variant="standard" fullWidth size="small">
              <InputLabel>Key</InputLabel>
              <Select
                id="test_self_register_selectKey"
                size="small"
                labelId="key-select"
                value={selectedKey}
                onChange={handleKeyChange}
              >
                {filteredKeys.length > 0 &&
                  filteredKeys.map(k => (
                    <MenuItem value={k} key={k} size="small">
                      {k.key_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          {moveFailedMessage && (
            <Typography sx={{ marginLeft: 4 }} variant="body2" color="error">
              {moveFailedMessage}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2, padding: '0 25px' }}>
            <GuestButton id="test_self_register_closeModalBtn" onClick={onCloseModal} variant="contained" size="small">
              Cancel
            </GuestButton>
            <GuestButton
              id="test_self_register_submitModalBtn"
              onClick={onSubmitKey}
              disabled={!selectedKey}
              sx={{ marginLeft: 3 }}
              variant="contained"
              size="small"
            >
              Submit
            </GuestButton>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

UserDevices.propTypes = {
  deviceList: PropTypes.object,
  onDeviceDelete: PropTypes.func,
  showHeader: PropTypes.bool,
  type: PropTypes.array,
  userInfo: PropTypes.object.isRequired,
  keys: PropTypes.array,
  refreshUserKeys: PropTypes.func,
  setErrorMessage: PropTypes.func,
  setShowError: PropTypes.func,
  withMove: PropTypes.func,
};

UserDevices.defaultProps = {
  deviceList: [],
  onDeviceDelete: () => {},
  showHeader: true,
  type: deviceTypes,
  keys: [],
  refreshUserKeys: () => {},
  setErrorMessage: () => {},
  setShowError: () => {},
  withMove: false,
};

export default UserDevices;
