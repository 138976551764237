/* eslint-disable no-console */
import React, { useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { useLocation, useNavigate } from 'react-router-dom';

import getI18nService from '../../i18n/i18n';
import { Context } from '../../store/Store';
import { coaStatusEnum, statusEnum } from '../../common/metadata/DataEnums';
import userService from '../../api/UserService';
import { useInterval } from '../../common/Hooks';
import { callFetchData } from '../../common/Fetch';
// import GuestButton from '../includes/GuestButton';
import HourGlass from '../../components/HourGlass';

const i18n = getI18nService();
const endCoaStatuses = [coaStatusEnum.ack, coaStatusEnum.nAck];

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CheckSSORequestStatus = () => {
  const [coaStatus, setCoaStatus] = useState(null);
  const query = useQuery();
  const navigate = useNavigate();
  const isCoaComplete = coas => endCoaStatuses.indexOf(coas) > -1;

  const handleSsoStatusChecks = async () => {
    const sessionId = query.get('sessionId');
    if (status === statusEnum.unknown) {
      return;
    }
    const api = Object.assign({}, userService.FetchSessionStatus, {
      data: { sessionId },
    });
    console.log(`Running SSO status check for ${sessionId}.`);
    const result = await callFetchData(api);
    if (result && result.status <= 204) {
      const { resp } = result;
      setCoaStatus(resp.coaStatus);
      if (isCoaComplete(resp.coaStatus)) {
        window.location.href = `/requestAccessSuccess?deviceType=${resp.deviceType}`;
      }
    }
  };

  useEffect(() => {
    if (coaStatus === coaStatusEnum.nAck) {
      navigate({
        pathname: '/error',
      });
    }
  }, [coaStatus]);

  useInterval(() => {
    console.log('Setting up status check');
    handleSsoStatusChecks();
  }, 5000);

  return (
    <Paper
      elevation={0}
      sx={{
        minWidth: 400,
        borderRadius: 4,
        m: 2,
        mt: 10,
        boxShadow: 'rgb(0 0 0 / 25%) 2px 4px 10px 4px',
        border: '1px solid rgb(81, 81, 81)',
      }}
    >
      <Box
        sx={{
          minWidth: 400,
          mx: 'auto',
          m: 4,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', color: 'primary.main' }}>
          <Box
            sx={{
              textAlign: 'center',
              fontSize: 'h6.fontSize',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {isCoaComplete(coaStatus) && <Box>{i18n.t('Messages.sponsoredAccessGranted')}</Box>}
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center', p: 1, mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {!isCoaComplete(coaStatus) && (
            <>
              {/* <CircularProgress /> */}
              <Box style={{ width: 30, height: 60 }}>
                <HourGlass />
              </Box>
              <Typography variant="caption" component="div">
                {i18n.t('Messages.grantingAccess')}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default CheckSSORequestStatus;
