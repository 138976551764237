/* eslint-disable no-console */
import React, { useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import KeyIcon from '@mui/icons-material/Key';
import CableIcon from '@mui/icons-material/Cable';

import { useLocation } from 'react-router-dom';

import getI18nService from '../../i18n/i18n';
import { Context } from '../../store/Store';
// import { getStorageItem } from '../../common/Storage';
import { callFetchData } from '../../common/Fetch';
import userService from '../../api/UserService';
import Logo from '../includes/Logo';

import { typeWired, typeWireless, getAllowedTypes, getAllDevices } from './Utils';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  width: 200,
  height: 175,
  border: `2px solid ${theme.palette.grey['400']}`,
}));

const i18n = getI18nService();
// const env = window['USERSERVICE_ENV'] || 'dev';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const typeDefault = typeWireless;

const Welcome = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [state, dispatch] = useContext(Context);
  // const [userInfo, setUserInfo] = useState(null);
  const { api } = state;
  const userInfo = api[userService.GetUserInfo.name];
  const { ui } = state;
  const { customization } = ui;
  const [allowWired, setAllowWired] = useState(false);
  const [allowWireless, setAllowWireless] = useState(false);
  const [checkingAllowed, setCheckingAllowed] = useState(true);
  let query = useQuery();
  const tenantToken = query.get('tenantToken');
  const sessionId = query.get('sessionId');
  // const { tenantToken, sessionId } = useParams();

  const fetchAllowedTypes = async () => {
    const result = await getAllowedTypes(tenantToken);
    if (result.allowWired) {
      setAllowWired(result.allowWired);
    }
    if (result.allowWireless) {
      setAllowWireless(result.allowWireless);
    }
    if (result) {
      dispatch({
        type: 'ui',
        payload: {
          allowWired: result.allowWired,
          allowWireless: result.allowWireless,
        },
      });
    }
    setCheckingAllowed(false);
  };

  useEffect(() => {
    setCheckingAllowed(true);
    fetchAllowedTypes();
  }, []);

  const onClickBtn = (type = typeWireless) => {
    navigate({
      pathname: 'register',
      search: createSearchParams({
        type,
      }).toString(),
    });
  };

  const checkIfUserHasDevices = async () => {
    const result = await getAllDevices(userInfo);
    if (result) {
      const wireless = result.find(i => i.device_type === typeWireless);
      const wired = result.find(i => i.device_type === typeWired);
      if (wireless) {
        onClickBtn(typeWireless);
      } else if (wired) {
        onClickBtn(typeWired);
      }
    }
  };

  useEffect(() => {
    if (userInfo && (allowWired || allowWireless)) {
      checkIfUserHasDevices();
    }
  }, [userInfo, allowWired, allowWireless]);

  return (
    <Box
      id="test_self_register_welcome"
      sx={{
        minWidth: 1280,
        height: '90%',
        borderRadius: 4,
        mx: 'auto',
        mt: 8,
        mb: 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 2,
        }}
      >
        {customization && <Logo width={50} height={30} data={customization.logo.data} css={customization.logo.css} />}
        <Box sx={{ textAlign: 'center', p: 1, fontSize: 'h4.fontSize' }}>{`Nilesecure`}</Box>
      </Box>

      <Box sx={{ textAlign: 'center', p: 1 }}>
        {!checkingAllowed && userInfo && (
          <Typography id="test_self_register_welcome_user" variant="h5">{`${i18n.t('Vocabulary.welcome')} ${
            userInfo && userInfo['user_name']
          }!`}</Typography>
        )}
        {!checkingAllowed && (allowWired || allowWireless) && (
          <Typography variant="h6" gutterBottom>
            {i18n.t('SelfRegister.choices')}
          </Typography>
        )}
        {!checkingAllowed && !allowWired && !allowWireless && (
          <Typography variant="h6" gutterBottom>
            {i18n.t('SelfRegister.choicesNone')}
          </Typography>
        )}
        {checkingAllowed && (
          <Typography variant="h6" gutterBottom>
            {`${i18n.t('SelfRegister.loading')}...`}
          </Typography>
        )}

        <Stack direction="row" mt={10} spacing={2} alignItems="center" justifyContent="center">
          {allowWireless && (
            <Item>
              <IconButton
                id="test_self_register_welcome_createBtn"
                sx={{ color: theme.palette.primary.main }}
                aria-label={i18n.t('SelfRegister.createKey')}
                onClick={() => onClickBtn(typeWireless)}
                disabled={!allowWireless}
              >
                <KeyIcon fontSize="large" style={{ fontSize: 96 }} />
              </IconButton>
              <Typography>{i18n.t('SelfRegister.createKey')}</Typography>
            </Item>
          )}
          {allowWired && (
            <Item>
              <IconButton
                id="test_self_register_welcome_regWiredBtn"
                sx={{ color: theme.palette.primary.main }}
                aria-label={i18n.t('SelfRegister.registerWiredDevice')}
                onClick={() => onClickBtn(typeWired)}
                // disabled={!allowWired}
              >
                <CableIcon style={{ fontSize: 96 }} />
              </IconButton>
              <Typography>{i18n.t('SelfRegister.registerWiredDevice')}</Typography>
            </Item>
          )}
          {/* {!allowWireless && !allowWired && (
            <Item>
              <Typography>{i18n.t('SelfRegister.notAllowed')}</Typography>
            </Item>
          )} */}
        </Stack>
      </Box>
      {/* )} */}
    </Box>
  );
};

Welcome.propTypes = {};

export default Welcome;
